import React from 'react';

function Select({ options, ...props }) {
  return (
    <select className="form-select" {...props}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

export default Select;