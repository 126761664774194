import React from 'react';

function ForecastsTableRow(props) {
    return (
        <tr>
            <td>{props.forecast.datetime.substring(11, 16)}</td>
            <td>{props.forecast.wave_height_min}{props.forecast.wave_height_max !== props.forecast.wave_height_min ? '-' + props.forecast.wave_height_max : ''}m</td>
            <td style={{color: props.forecast.wind_speed >= 50 ? "#b90f0f" : "inherit"}}>{props.forecast.wind_speed} km/h</td>
            <td><i className="fa-solid fa-arrow-up-long"
                   style={{transform: "rotate(" + (180 + props.forecast.wind_direction) + "deg)"}}></i> {props.forecast.wind_direction_description}
            </td>
        </tr>
    );
}

export default ForecastsTableRow;