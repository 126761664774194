import React from 'react';
import axios from 'axios';
import SearchForm from './components/SearchForm/SearchForm';
import SpotsMap from "./components/SpotsMap/SpotsMap";
import './App.css';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            spots: [],
            searchMode: null,
            location: null,
            mapDisplayed: false,
            searchResultMessage: null,
        };
    }

    render() {
        return (
            <div className="app">
                <div id={"form"} className={"layer full-screen"}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-xxl-4 col-xl-5 col-lg-6 col-md-8 col-sm-10 ms-auto me-auto">
                                <SearchForm onSubmit={this.handleSearchFormSubmit}
                                            searchresultmessage={this.state.searchResultMessage}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div id={"bg-image"} className={"layer full-screen"}/>
                <div id={"map"} className={"layer full-screen faded"}>
                    {this.state.mapDisplayed &&
                        <SpotsMap location={this.state.location} spots={this.state.spots}
                                  searchMode={this.state.searchMode}
                                  onSearchThisAreaButtonClick={this.handleSearchFormSubmit}/>}
                </div>
            </div>
        );
    }

    dec2hex = (dec) => {
        return dec.toString(16).padStart(2, "0")
    }

    generateId = (len) => {
        let arr = new Uint8Array((len || 40) / 2);
        window.crypto.getRandomValues(arr);
        return Array.from(arr, this.dec2hex).join('');
    }

    handleSearchFormSubmit = (event = null) => {

        this.setState({searchResultMessage: null});

        let formElement = document.getElementById('search-form');
        let formData;

        if (event !== null)
            event.preventDefault();

        formData = new FormData(formElement);

        const data = Object.fromEntries(formData.entries());
        const isDev = window.location.hostname.includes('localhost') || window.location.hostname.includes('docker');
        const axiosInstance = axios.create({
            baseURL: isDev ? 'https://api.lifekinetik-shop.de' : 'https://api.lifekinetik-shop.de',
            timeout: 60000,
            headers: {'Content-Type': 'application/json'},
        });
        formElement.classList.add("loading");

        if(!data.hasOwnProperty('user_id') || data.user_id === "" || data.user_id === null || data.user_id === undefined) {
            data.user_id = this.generateId();

            // add user_id to the form fields
            const userIdInput = document.getElementById('user-id');
            if (userIdInput !== null) {
                userIdInput.value = data.user_id;
            }
        }

        localStorage.setItem('searchFormData', JSON.stringify(data));

        let postData = {
            wave_height_min: data.wave_height_min,
            wave_height_max: data.wave_height_max,
            date_from: data.date_from,
            date_to: data.date_to,
            hour_from: data.hour_from,
            hour_to: data.hour_to,
            good_wind_only: data.good_wind_only,
            city: data.city,
            latitude: data.latitude,
            longitude: data.longitude,
            radius: data.radius,
            bounding_box: data.bounding_box,
            user_id: data.user_id,
        };

        // if no_save cookie is set, add no_save to the post data
        if (document.cookie.split(';').some((item) => item.trim().startsWith('no_save='))) {
            postData.no_save = 1;
        }

        axiosInstance.post('/forecasts', postData).then(response => {
            setTimeout(() => {
                formElement.classList.remove("loading");

                const error = response.data?.error;
                const spots = response.data.spots ?? [];
                const location = response.data.location ?? null;
                const searchMode = response.data.searchMode ?? null;

                if (error) {
                    this.setState({
                        mapDisplayed: false,
                        spots: [],
                        location: null,
                        searchResultMessage: {
                            title: "Error",
                            text: error
                        }
                    });
                    return;
                }

                if (spots.length === 0 && searchMode !== 'boundingBox') {
                    this.setState({
                        mapDisplayed: false,
                        spots: [],
                        location: null,
                        searchResultMessage: {
                            title: "No spots with that conditions found",
                            text: "In the selected area and time range, no spots will have the specified conditions. Please try again with different settings."
                        }
                    });
                    return;
                }


                this.setState({
                    spots: spots,
                    searchMode: searchMode,
                    location: location,
                    searchResultMessage: null,
                    mapDisplayed: true
                });

                document.getElementById('form').classList.add('faded');
                document.getElementById('form').classList.add('map-active');

                // add location to the form fields
                const latitudeInput = document.getElementById('latitude');
                const longitudeInput = document.getElementById('longitude');

                if (latitudeInput !== null && longitudeInput !== null) {
                    latitudeInput.value = location.latitude;
                    longitudeInput.value = location.longitude;
                }

                setTimeout(() => {
                    document.getElementById('form').classList.add('hidden');
                }, 500);

                document.getElementById('map').classList.remove('hidden');
                document.getElementById('map').classList.remove('faded');

                // add hidden class to the search-this-area button
                const searchThisAreaButton = document.querySelector('.search-this-area');
                if (searchThisAreaButton !== null) {
                    searchThisAreaButton.classList.add('hidden');
                    searchThisAreaButton.removeAttribute('disabled');
                }

            }, 500);
        }).catch(error => {
            setTimeout(() => {
                formElement.classList.remove("loading");
                console.log("Error", error);

                this.setState({
                    mapDisplayed: false,
                    spots: [],
                    location: null,
                    searchResultMessage: {
                        title: "Error",
                        text: "An error occurred while fetching the data. Please try again later. " + error.message + "."
                    }
                });

            }, 500);
        })
    }
}

export default App;
