import React, {Component} from 'react';
import Button from "../Button/Button";
import TextInput from "../Inputs/TextInput";
import DateInput from "../Inputs/DateInput";
import Select from "../Inputs/Select";
import NumberInput from "../Inputs/NumberInput";

export class SearchForm extends Component {

    state = {
        autocompleteResults: null,
        autocompleteLoading: false,
        savedFormData: null,
        preFocusCityValue: "",
    };

    lastKeyStrokeTimestampInMs = 0;
    mapBoxTimeout = null;

    getDateIn16Days = () => {
        let date = new Date();
        date.setDate(date.getDate() + 16);
        return date.toISOString().substring(0, 10);
    }

    getDate90DaysAgo = () => {
        let date = new Date();
        date.setDate(date.getDate() - 90);
        return date.toISOString().substring(0, 10);
    }
    onCityFocus = () => {
        // save current value, clear input + placeholder
        const cityInput = document.getElementById('city');

        this.setState({
            preFocusCityValue: cityInput.value
        });

        cityInput.value = "";
        cityInput.placeholder = "";
    };

    onCityBlur = () => {
        const cityInput = document.getElementById('city');

        if (cityInput.value === "") {
            cityInput.value = this.state.preFocusCityValue;
            cityInput.placeholder = "Nazaré, Portugal";
        }
    }

    componentDidMount() {
        let savedFormData = localStorage.getItem('searchFormData');
        if (savedFormData) {
            savedFormData = JSON.parse(savedFormData);

            if (savedFormData) {

                let cityInput = document.getElementById('city');
                let latitudeInput = document.getElementById('latitude');
                let longitudeInput = document.getElementById('longitude');
                let waveHeightFromInput = document.getElementById('wave-height-from');
                let waveHeightToInput = document.getElementById('wave-height-to');
                let goodWindOnlyInput = document.getElementById('good-wind-only');
                let radiusInput = document.getElementById('radius');
                let levelInput = document.getElementById('level');
                let user_idInput = document.getElementById('user-id');

                cityInput.value = savedFormData.city ?? "";
                latitudeInput.value = savedFormData.latitude ?? "";
                longitudeInput.value = savedFormData.longitude ?? "";
                waveHeightFromInput.value = savedFormData.wave_height_min ?? this.getMinWaveHeight("Kook");
                waveHeightToInput.value = savedFormData.wave_height_max ?? this.getMaxWaveHeight("Kook");
                goodWindOnlyInput.checked = savedFormData.good_wind_only ?? false;
                radiusInput.value = savedFormData.radius ?? 50;
                levelInput.value = savedFormData.level ?? "Kook";
                user_idInput.value = savedFormData.user_id ?? "";

                this.setState({
                    savedFormData: savedFormData
                });

                if (cityInput.value.startsWith("Current Location")) {
                    this.useCurrentLocation();
                }

                this.toggleCustomHeightCollapsables(savedFormData.level ?? "Kook");

                this.checkSubmitButtonDisabled();
            }
        }

        let hoursFrom = document.getElementById('hour-from');
        let currentHour = Math.max(Math.min(new Date().getHours(), 21), 6);
        let validHours = [0, 3, 6, 9, 12, 15, 18, 21];

        for (let i = 0; i < validHours.length; i++) {
            if (validHours[i] > currentHour) {
                currentHour = validHours[i - 1];
                break;
            }
        }
        hoursFrom.value = currentHour;

        if (currentHour === 21) {
            // set date to tomorrow
            let dateTo = document.getElementById('date-to');
            let dateFrom = document.getElementById('date-from');
            let tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            dateTo.value = tomorrow.toISOString().substring(0, 10);
            dateFrom.value = tomorrow.toISOString().substring(0, 10);
            hoursFrom.value = 6;
        }
    }

    onCityChanged = () => {
        const now = new Date().getTime();
        const cityInput = document.getElementById('city');
        const boundingBoxInput = document.getElementById('bounding-box');
        const latitudeInput = document.getElementById('latitude');
        const longitudeInput = document.getElementById('longitude');
        boundingBoxInput.value = "";
        latitudeInput.value = "";
        longitudeInput.value = "";

        cityInput.placeholder = "Nazaré, Portugal";

        this.checkSubmitButtonDisabled();

        if (cityInput.value.length >= 3) {

            if (this.mapBoxTimeout) {
                clearTimeout(this.mapBoxTimeout);
            }

            this.setState({
                autocompleteNoResults: false,
                autocompleteResults: null,
                autocompleteLoading: true
            });

            this.mapBoxTimeout = setTimeout(() => {
                const mapBoxApiUrl = "https://api.mapbox.com/geocoding/v5/mapbox.places/" + encodeURIComponent(cityInput.value) + ".json?access_token=pk.eyJ1IjoiYm9laG1pMTk4OCIsImEiOiJjbHQzam95MTAxNnl4MmtyemJ2ZGJwdGY5In0.pjEbl3RSJmqiIhh7D83vTg&types=place&proximity=ip&limit=5";
                fetch(mapBoxApiUrl)
                    .then(response => response.json())
                    .then(data => {
                        if (data.features && data.features.length) {
                            this.setState({
                                autocompleteLoading: false,
                                autocompleteResults: data.features,
                                autocompleteNoResults: false
                            });
                        } else {
                            this.setState({
                                autocompleteNoResults: true,
                                autocompleteResults: null,
                                autocompleteLoading: false
                            });
                        }
                    });
            }, 2000);
        } else {
            clearTimeout(this.mapBoxTimeout);
            this.setState({
                autocompleteResults: null,
                autocompleteLoading: false,
                autocompleteNoResults: false
            });
        }

        this.lastKeyStrokeTimestampInMs = now;
    }

    onDateChanged = () => {
        const dateFromInput = document.getElementById('date-from');
        const dateToInput = document.getElementById('date-to');
        const dateFrom = new Date(dateFromInput.value);
        const dateTo = new Date(dateToInput.value);

        if (dateFrom > dateTo) {
            dateToInput.value = dateFromInput.value;
        }

        dateToInput.setAttribute('min', dateFromInput.value);
    }

    onTimeFromChanged = () => {
        const hourFromInput = document.getElementById('hour-from');
        const hourToInput = document.getElementById('hour-to');

        const hourFrom = parseInt(hourFromInput.value);
        const hourTo = parseInt(hourToInput.value);

        if (hourTo < hourFrom) {
            hourToInput.value = hourFromInput.value;
        }
    }

    onTimeToChanged = () => {
        const hourFromInput = document.getElementById('hour-from');
        const hourToInput = document.getElementById('hour-to');

        const hourFrom = parseInt(hourFromInput.value);
        const hourTo = parseInt(hourToInput.value);

        if (hourTo < hourFrom) {
            hourFromInput.value = hourToInput.value;
        }
    }

    useCurrentLocation = () => {
        // get lat & long from device location and add to the according form fields
        if (navigator.geolocation) {
            const cityInput = document.getElementById('city');
            const latitudeInput = document.getElementById('latitude');
            const longitudeInput = document.getElementById('longitude');
            const boundingBoxInput = document.getElementById('bounding-box');

            cityInput.placeholder = "Detecting location...";
            cityInput.value = "";
            boundingBoxInput.value = "";
            latitudeInput.value = "";
            longitudeInput.value = "";

            this.setState({
                autocompleteResults: null,
                autocompleteLoading: false,
                autocompleteNoResults: false
            });

            clearTimeout(this.mapBoxTimeout);

            this.checkSubmitButtonDisabled();

            navigator.geolocation.getCurrentPosition((position) => {
                latitudeInput.value = position.coords.latitude;
                longitudeInput.value = position.coords.longitude;
                cityInput.value = "Current Location (" + position.coords.latitude + ", " + position.coords.longitude + ")";
                this.checkSubmitButtonDisabled();
            }, (error) => {
                cityInput.value = "";
                cityInput.placeholder = "Nazaré, Portugal";
                this.checkSubmitButtonDisabled();

                console.error(error);
                alert("Could not detect your location. Please enter a city manually.\n\n" + error.message);
            });
        } else {
            alert("Could not detect your location. Please enter a city manually.");
        }
    }

    onClose = () => {
        document.getElementById('form').classList.add('faded');

        setTimeout(() => {
            document.getElementById('form').classList.add('hidden');
        }, 500);
    }

    const
    levelToWaveHeightMapping = {
        "Kook": [0.3, 0.9],
        "Intermediate": [0.5, 1.6],
        "Pro": [1.2, 4.0],
        "Lunatic": [3.0, 30.0],
    }

    checkSubmitButtonDisabled() {
        //const city = document.getElementById('city')?.value ?? "";
        const latitude = document.getElementById('latitude')?.value ?? "";
        const longitude = document.getElementById('longitude')?.value ?? ""
        const boundingBox = document.getElementById('bounding-box')?.value ?? "";

        if ((latitude === "" || longitude === "") && boundingBox === "") {
            document.getElementById('submit').setAttribute('disabled', 'disabled');
        } else {
            document.getElementById('submit').removeAttribute('disabled');
        }
    }

    render() {
        return (<form id={"search-form"} className="search-form row g-3" {...this.props} method={"post"}>
                <div className={"close fa-solid fa-xmark"} onClick={this.onClose}></div>
                <div className="col-8">
                    <label htmlFor="city" className="form-label">Location</label>
                    <div className={"input-group"}>
                        <TextInput autoComplete={"off"} id="city" name="city" placeholder="Nazaré, Portugal"
                                   onChange={this.onCityChanged}
                                   onFocus={this.onCityFocus}
                                   onBlur={this.onCityBlur}
                                   required/>
                        <span id="use-location" className="input-group-text" onClick={this.useCurrentLocation}><i
                            className="fa-solid fa-location-crosshairs"></i></span>
                    </div>
                    <div className={"input-group"}>
                        {this.state.autocompleteResults !== null &&
                            <div className={"autocomplete-results"} id={"autocomplete-results"}>
                                {this.state.autocompleteResults && this.state.autocompleteResults.map((result, index) => {
                                    return <div key={index} className={"autocomplete-result"}
                                                onClick={this.autocompleteResultClick(result)}>{result.place_name}</div>
                                })}
                            </div>
                        }
                        {this.state.autocompleteLoading &&
                            <div className={"autocomplete-results loading"} id={"autocomplete-results"}>
                                <div className={"autocomplete-result"}>
                                    <div className="loader">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                        }
                        {this.state.autocompleteNoResults &&
                            <div className={"autocomplete-results no-results"} id={"autocomplete-results"}>
                                <div className={"autocomplete-result"} onClick={() => {
                                    this.setState({autocompleteNoResults: false})
                                }}>No results found.
                                </div>
                            </div>
                        }
                    </div>
                    <input type="hidden" id="user-id" name="user_id"/>
                    <input type="hidden" id="latitude" name="latitude"/>
                    <input type="hidden" id="longitude" name="longitude"/>
                    <input type="hidden" id="bounding-box" name="bounding_box" value=""/>
                </div>
                <div className="col-4">
                    <label htmlFor="radius" className="form-label">Distance</label>
                    <div className={"input-group"}>
                        <NumberInput name="radius" id="radius" min="1" max="99" placeholder="50" defaultValue={50}
                                     required/>
                        <span className="input-group-text">km</span>
                    </div>
                </div>
                <div className="col-6">
                    <label htmlFor="date-from" className="form-label">From Date</label>
                    <DateInput name="date_from" id="date-from" placeholder="Date"
                               defaultValue={new Date().toISOString().substring(0, 10)}
                               min={new Date().toISOString().substring(0, 10)}
                               max={this.getDateIn16Days()}
                               onChange={this.onDateChanged} required/>
                </div>
                <div className="col-6">
                    <label htmlFor="date-to" className="form-label">To Date</label>
                    <DateInput name="date_to" id="date-to" placeholder="Date"
                               defaultValue={new Date().toISOString().substring(0, 10)}
                               min={new Date().toISOString().substring(0, 10)}
                               max={this.getDateIn16Days()}
                               onChange={this.onDateChanged}
                               required/>
                </div>
                <div className="col-6">
                    <label htmlFor="date-from" className="form-label">Time Range</label>
                    <select className="form-select" id={"hour-from"} name="hour_from"
                            onChange={this.onTimeFromChanged}>
                        <option value="0">00:00</option>
                        <option value="3">03:00</option>
                        <option value="6">06:00</option>
                        <option value="9">09:00</option>
                        <option value="12">12:00</option>
                        <option value="15">15:00</option>
                        <option value="18">18:00</option>
                        <option value="21">21:00</option>
                    </select>
                </div>
                <div className="col-6">
                    <label htmlFor="date-from" className="form-label">&nbsp;</label>
                    <select className="form-select" id={"hour-to"} name="hour_to" onChange={this.onTimeToChanged}
                            defaultValue={21}>
                        <option value="0">00:00</option>
                        <option value="3">03:00</option>
                        <option value="6">06:00</option>
                        <option value="9">09:00</option>
                        <option value="12">12:00</option>
                        <option value="15">15:00</option>
                        <option value="18">18:00</option>
                        <option value="21">21:00</option>
                    </select>
                </div>
                <div className="col-12">
                    <label htmlFor="level" className="form-label">Wave Heights</label>
                    <Select id="level"
                            name="level"
                            onChange={this.handleLevelChange}
                            options={[
                                {value: "Kook", label: this.getWaveHeightLabelText("Kook")},
                                {value: "Intermediate", label: this.getWaveHeightLabelText("Intermediate")},
                                {value: "Pro", label: this.getWaveHeightLabelText("Pro")},
                                {value: "Lunatic", label: this.getWaveHeightLabelText("Lunatic")},
                                {value: "Custom", label: "Custom"},
                                {value: "All", label: "All"}
                            ]}/>
                </div>
                <div className="col-6 collapse custom-wave-height-input">
                    <label htmlFor="wave-height-from" className="form-label">Min. Height</label>
                    <div className={"input-group"}>
                        <NumberInput name="wave_height_min" id="wave-height-from"
                                     defaultValue={this.getMinWaveHeight("Kook")}
                                     min="0.0"
                                     max="50" step={0.1} placeholder="0,5"/>
                        <span className="input-group-text">m</span>
                    </div>
                </div>
                <div className="col-6 collapse custom-wave-height-input">
                    <label htmlFor="wave-height-to" className="form-label">Max. Height</label>
                    <div className={"input-group"}>
                        <NumberInput name="wave_height_max" id="wave-height-to"
                                     defaultValue={this.getMaxWaveHeight("Kook")}
                                     min="0.1"
                                     max="50" step={0.1} placeholder="2,2"/>
                        <span className="input-group-text">m</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="good-wind-only" name="good_wind_only"
                               value={1}/>
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Good wind conditions
                            only <small>(offshore, glassy or very low cross/onshore
                                speeds, no storm)</small></label>
                    </div>
                </div>
                <div className="col-12 text-center">
                    <div className="button-wrap">
                        <Button id="submit" type="submit" text="Find Spots" disabled/>
                        <div className="loader">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
                {
                    this.props.searchresultmessage &&
                    <div className={"col-12 search-result-message"}>
                        <p>
                            <strong>{this.props.searchresultmessage.title}</strong><br/>
                            {this.props.searchresultmessage.text}
                        </p>
                    </div>
                }
            </form>
        )
            ;
    }

    getMinWaveHeight = (level) => {
        return this.levelToWaveHeightMapping[level][0] ?? 0;
    }

    getMaxWaveHeight = (level) => {
        return this.levelToWaveHeightMapping[level][1] ?? 0;
    }


    getWaveHeightLabelText = (level) => {
        return level + " (" + this.getMinWaveHeight(level) + "m - " + this.getMaxWaveHeight(level) + "m)";
    }

    toggleCustomHeightCollapsables = (value) => {
        let customWaveInputs = document.getElementsByClassName('custom-wave-height-input');
        for (let i = 0; i < customWaveInputs.length; i++) {
            let collapse = window.bootstrap.Collapse.getOrCreateInstance(customWaveInputs[i], {toggle: false});

            if (!collapse)
                return;

            if (value === "Custom") {
                collapse.show();
            } else {
                collapse.hide();
            }
        }
    }


    handleLevelChange = (event) => {
        let value = event.target.value;
        let heightFromElement = document.getElementById('wave-height-from');
        let heightToElement = document.getElementById('wave-height-to');

        if (value !== "Custom" && value !== "All" && value !== "") {
            heightFromElement.value = this.levelToWaveHeightMapping[value][0] || 0.5;
            heightToElement.value = this.levelToWaveHeightMapping[value][1] || 2.2;
        }

        if (value === "All") {
            heightFromElement.value = 0;
            heightToElement.value = 50;
        }

        this.toggleCustomHeightCollapsables(value);
    }

    autocompleteResultClick = (result) => {
        return () => {
            const cityInput = document.getElementById('city');
            const latitudeInput = document.getElementById('latitude');
            const longitudeInput = document.getElementById('longitude');
            const boundingBoxInput = document.getElementById('bounding-box');

            cityInput.value = result.place_name;
            latitudeInput.value = result.center[1];
            longitudeInput.value = result.center[0];
            boundingBoxInput.value = "";

            this.setState({
                autocompleteResults: null
            });

            this.checkSubmitButtonDisabled();
        }
    }
}


export default SearchForm;
